import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { DAppProvider, Config } from '@usedapp/core';
import './index.css';
import App from './App';

const parseNetworkUrl = (): string => {
  if (process.env.REACT_APP_INFURA_APIKEY !== undefined) {
    return `${process.env.REACT_APP_NETWORK_URL}${process.env.REACT_APP_INFURA_APIKEY}`
  }
  return process.env.REACT_APP_NETWORK_URL as string
}

const readOnlyUrls = {
  [process.env.REACT_APP_CHAINID as any]: parseNetworkUrl(),
}

const config: Config = {
  readOnlyChainId: parseInt(process.env.REACT_APP_CHAINID as string),
  readOnlyUrls: readOnlyUrls
}

const container = document.getElementById('root') as HTMLElement

const root = ReactDOMClient.createRoot(container);

root.render(
  <React.StrictMode>
  <DAppProvider config={config}>
    <App />
  </DAppProvider>
</React.StrictMode>,
)
