import './Button.css'

type ButtonProps = {
    onClick: (event: any) => void
    disabled?: boolean
    children: any
    styleName: string
}

const MintButton = ({ onClick, children, styleName, disabled, ...props }: ButtonProps) =>
    <button disabled={disabled} onClick={onClick} className={`button-base ${styleName}`} {...props}>{children}</button>;

export default MintButton;
