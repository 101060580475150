import './ConnectButton.css'
import Button from './Button';

type ConnectButtonPrrops = {
    connectHandler: () => void
    active: boolean
}
const  ConnectButton = ({ connectHandler, active}: ConnectButtonPrrops) =>
    <Button onClick={connectHandler} styleName={`connect-button ${active ? "connect-button-disconnected" : ""}`}>
       <div className='connect-button-text'>
        {active ? 'disconnect': 'connect'}
       </div> 
    </Button>;

export default ConnectButton;