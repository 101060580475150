import React, { useEffect, useState } from "react";
import { useEthers, useContractFunction } from "@usedapp/core";
import { BigNumber, utils } from "ethers";
import badge from "./badge";
import apefather from "./images/Apefather.png";
import "./App.css";
import MintButton from "./components/MintButton";
import ConnectButton from "./components/ConnectButton";

const BADGE_PRICE = ".069";
const ERROR_MESSAGE_PREFIX = "Unable to process transaction.";

function App() {
  const { activateBrowserWallet, deactivate, active, account } = useEthers();

  const { state: {
    status,
    errorMessage,
    receipt,
  }, resetState, send } = useContractFunction(badge, "mintBadge");
  
  const [currentState, setCurrentState] = useState("None")
  const [isMintButtonDisabled, setIsMintButtonDisabled] = useState(true);
  const [badgeCount, setBadgeCount] = useState(0);
  const [transHash, setTransHash] = useState("");

  useEffect(() => {
    // activate mint button only when wallet is connected
    if (active && !!account) {
      setIsMintButtonDisabled(false);
    } else {
      setIsMintButtonDisabled(true);
    }
  }, [active, account])

  useEffect(() => {
    // keep track of mint flow state. we start with None, and end with either success or exception
    switch (status) {
      case "None":
        if (currentState === "Complete") {
          setCurrentState(status);
          setIsMintButtonDisabled(false);
        }
        break;
      case "Success":
      case "Exception":
        setCurrentState("Complete");
        break;
      default:
        setIsMintButtonDisabled(true);
        setCurrentState("Processing");
        break
    }
  }, [status])

  useEffect(() => {
    // handle exception states
    if (status === "Exception") {
      if (errorMessage?.includes("User denied transaction signature")) {
        console.log("User rejected transaction. Resetting state");
      } else {
        let friendlyMessage = errorMessage;
        if (errorMessage?.includes("enable Blind signing")) {
          friendlyMessage = "The hardware device requires Blind signing to be enabled.";
        } else if (errorMessage?.includes("insufficient funds")) {
          friendlyMessage = "The connected account has insufficient funds.";
        }
        alert(`${ERROR_MESSAGE_PREFIX} ${friendlyMessage}`);
      }
    }
    resetState();
    // TODO: do we need resetState here?
  }, [status, resetState, errorMessage])

  useEffect(() => {
    // handle success states
    if (status === "Success") {
      const transHash = receipt?.transactionHash ?? '';
      setTransHash(transHash);
      alert(`transaction successful! tx: ${transHash}`)
      resetState();
    }
    // TODO: do we need resetState here?
  }, [status, resetState])

  const handleMint = (badgeCount: number) => {
    if (active && account) {
      setBadgeCount(badgeCount);
      if (badgeCount === 1) {
        send(badgeCount, { value: utils.parseEther(BADGE_PRICE) });
      } else {
        send(badgeCount, { value: BigNumber.from(2).mul(utils.parseEther(BADGE_PRICE)) });
      }
    }
  }

  return (
    <div className="App">
      <div className="header-nav-container">
        <span className="header-nav-icon">
          Apestarter
        </span>
        <ConnectButton connectHandler={!!account ? deactivate : activateBrowserWallet} active={!!account} />
      </div>

      <div className="main-section-container">
        <div className="main-section-header">
          <h1 className="main-section-header-super">Apestarter</h1>
          <span className="main-section-header-sub">
            by NFTstarter
          </span>
        </div>

        <div className="main-section-summary">
          <img className="main-section-summary-image" src={apefather} alt="ape father" />
          <div className="main-section-summary-information font-height">
            <h2 className="main-section-summary-header">Apestarter Founders Badge</h2>
            <span className="main-section-summary-body">
              A limited mint of 500 Apefather NFTs verifying their owners as Apestarter Community Founders<span className="period-spacing">. Apefather</span> NFT holders will be entitled to free + gas mints for select future Apestarter Launchpad projects. *
            </span>
            <div className="main-section-summary-mint">
              <ul className="summary-mint-details-list">
                <li>2 per wallet</li>
                <li>.069 eth each</li>
                <li>500 max supply</li>
              </ul>
              <p className="terms-notice">*By minting you agree to our <a href="/terms.html" target="_blank" rel="noopener noreferrer">terms</a>.</p>
              <div className="summary-mint-buttons">
                <MintButton handleMint={() => handleMint(1)} isDisabled={isMintButtonDisabled}>
                  <div className="mint-button-text">{badgeCount === 1 && currentState !== "None" ? "Processing" : "One"}</div>
                </MintButton>
                <MintButton handleMint={() => handleMint(2)} isDisabled={isMintButtonDisabled}>
                  <div className="mint-button-text">{badgeCount === 2 && currentState !== "None" ? "Processing" : "Two"}</div>
                </MintButton>
              </div>
              <div className="account-connected">
                {account}
              </div>
            </div>
          </div>
        </div>
        
        {!!transHash && <div className="transaction-result font-height">
          <span>transaction hash:&nbsp;</span>
          <a href={`https://etherscan.io/tx/${transHash}`} target="_blank" rel="noopener noreferrer">{transHash}</a>
        </div>
        }

        <div className="main-section-about-apestarter font-height">
          <h2>About Apestarter</h2>
          Apestarter is a grassroots NFT launchpad bringing creatives to the blockchain, the medium of freedom and eternity<span className="period-spacing">. We</span> want to ensure artists have the technical and cultural support to be successful.
        </div>

        <div className="main-section-description font-height">
          <h2>Roadmap</h2>
          <div className="roadmap-list">
            <ul>
              <li>First free + gas limited edition NFT claim in Q3 2023</li>
              <li>Inaugural Artist generative NFT in Q3 2023</li>
              <li>Apestarter generative NFT in Q4 2023</li>
              <li>Other exclusive perks for Apestarter NFT owners</li>
            </ul>
          </div>
        </div>

        <div className="founders">
          <h2>Team Founders</h2>
          <div className="founder-list-socials font-height">
            <ul>
              <li><a className="social-link" href="https://twitter.com/Mecha_Kong" target="_blank" rel="noopener noreferrer">Mecha_Kong</a></li>
              <li><a className="social-link" href="https://twitter.com/gme_of_coins" target="_blank" rel="noopener noreferrer">Game_of_Coins</a></li>
            </ul>
          </div>
        </div>

        <footer>
          <div className="footer-link-section">
            {/* TODO: update these links to final links */}
            <a className="badge-link" href="https://opensea.io/collection/apefatherbadge" target="_blank" rel="noopener noreferrer">OpenSea</a>|
            <a className="badge-link" href="https://etherscan.io/address/0x51700220877cA6f1E82DB11dDf89405FB52d4Aa0" target="_blank" rel="noopener noreferrer">Etherscan</a>|
            <a className="badge-link" href="https://twitter.com/apestarter" target="_blank" rel="noopener noreferrer">Twitter</a>
          </div>

          <div className="footer-link-section">
            <a className="badge-link" href="/terms.html" target="_blank" rel="noopener noreferrer">Terms</a>|
            <a className="badge-link" href="/privacypolicy.html" target="_blank" rel="noopener noreferrer">Privacy Policy</a>
          </div>
        </footer>
      </div>
    </div>
  );
}

export default App;
