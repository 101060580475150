import React from 'react';
import './MintButton.css';
import Button from './Button';

type MintButtonProps = {
  handleMint: () => void
  isDisabled: boolean
  children: any
}

const MintButton = ({handleMint, isDisabled, children}: MintButtonProps) => 
  <Button onClick={handleMint} disabled={isDisabled} styleName="mint-button">{children}</Button>;

export default MintButton;
